import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./home_others.css";

import BACKGROUND from "../assets/img/home2.jpg";

export default function Home_others() {
  return (
    <>
      <img src={BACKGROUND} alt="" className="homes__bg" />

      <div className="bd-container">
        <div className="home-others">
          <div className="home-others-contianer">
          <p>I can't think of what to write, so here's my cute little cat <strong>Alko</strong> for you to enjoy!</p>
            <div class="home-others-gallery-wrap">
              <div class="home-others-item item-1"></div>
              <div class="home-others-item item-2"></div>
              <div class="home-others-item item-3"></div>
              <div class="home-others-item item-4"></div>
              <div class="home-others-item item-5"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
